<template>
  <div class="d-flex flex-column" style="width: 100%">
    <div v-if="loading" style="min-height: 2px">
      <v-progress-linear indeterminate />
    </div>
    <div class="d-flex" style="width: 100%">
      <filter-sidebar-vue :filter.sync="filter" />
      <div class="d-flex flex-column" style="width: 100%">
        <data-section-vue
          :printList="
            orders.filter(
              (o) =>
                o.workspace === filter.workspace ||
                filter.workspace === '전체 작업장'
            )
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataSectionVue from "./DataSection.vue";
import FilterSidebarVue from "./FilterSidebar.vue";

export default {
  name: "Admin-Calendar",
  components: {
    DataSectionVue,
    FilterSidebarVue,
  },
  data() {
    return {
      filter: { workspace: "전체 작업장" },
      orders: [],
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      const orderDB = this.$firebase.firestore().collection("orders");

      if (this.unsubscribe) this.unsubscribe(); // 기존 구독 해제
      this.unsubscribe = orderDB
        .orderBy("paidAt", "desc")
        .where("status", "in", ["Progress"])
        .limit(200)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const item = { id: change.doc.id, ...change.doc.data() };
            this.formatTimestamps(item);

            if (change.type === "added") {
              this.orders.push(item);
            } else if (change.type === "modified") {
              this.updateOrder(item);
            } else if (change.type === "removed") {
              this.removeOrder(item);
            }
          });
        });
    },
    formatTimestamps(item) {
      ["createdAt", "updatedAt", "paidAt", "dueAt", "shipAt"].forEach(
        (field) => {
          if (item[field] && item[field].toDate)
            item[field] = item[field].toDate();
        }
      );
    },
  },
};
</script>

<style scoped>
/* 필요한 스타일 추가 */
</style>
