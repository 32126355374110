<template>
  <div
    class="d-flex flex-column"
    style="
      min-width: 180px;
      gap: 12px;
      height: 100vh;
      border-right: 1px solid var(--v-background_dark-base);
    "
  >
    <div
      class="d-flex justify-space-between px-3 py-4"
      style="border-bottom: 1px solid var(--v-background_dark-base)"
    >
      <div class="title-1 font-weight-bold onSurface_dark--text align-center">
        일정 관리
      </div>
    </div>
    <div class="d-flex flex-column px-2 pb-3 subtitle-2">
      <div v-for="item in workspace" :key="item">
        <v-hover v-slot="{ hover }" close-delay="10">
          <div
            class="px-3 align-center d-flex"
            style="height: 28px; border-radius: 4px"
            :style="
              (hover || filter.workspace == item) &&
              'background: var(--v-background_normal-base)'
            "
            @click="changeWorkspace(item)"
          >
            {{ item }}
          </div>
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin-Order-FilterSideBar",
  props: { filter: Object },
  data() {
    return {
      workspace: ["전체 작업장", "메이드올 대구지사", "경희대학교 국제캠퍼스"],
    };
  },
  methods: {
    changeWorkspace(item) {
      this.filter.workspace = item;
      this.$emit("filter:update", this.filter);
    },
  },
};
</script>

<style></style>
