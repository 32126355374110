<template>
  <div class="pa-10 d-flex">
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card class="pa-4 rounded-lg" outlined max-width="1200px">
          <div
            v-for="order in printList"
            :key="order.id"
            style="width: inherit"
          >
            <div
              style="
                border-radius: 8px;
                border: 1px solid var(--v-background_normal-base);
                gap: 8px;
              "
              class="d-flex flex-column pa-3"
            >
              <div
                class="d-flex justify-space-between onSurface_normal--text title-2"
                style="gap: 16px"
              >
                <span>
                  {{ order.displayName }}
                </span>
                <span>
                  {{ order.purpose }}
                </span>
                <span>
                  {{ order.paidAt | moment("YY/MM/DD HH:mm") }}
                </span>
                <span>
                  {{ order.paymentMethod }}
                </span>
                <span>
                  {{ order.shippingMethod }}
                </span>
                <span> ~{{ order.dueAt | moment("YY/MM/DD") }} </span>
              </div>
              <div
                v-for="(file, index) in order.file.filter((v) => v.print)"
                :key="index"
                class="pl-2"
              >
                <div class="d-flex" style="gap: 8px">
                  <v-img
                    :src="file.previewImg"
                    :aspect-ratio="4 / 3"
                    width="120px"
                  />
                  <div class="d-flex flex-column" style="gap: 4px; width: 100%">
                    <div class="d-flex flex-column">
                      <v-hover v-slot="{ hover }" close-delay="100">
                        <div
                          class="subtitle-1 font-weight-bold mr-auto"
                          :class="
                            hover ? 'primary--text' : 'onSurface_dark--text'
                          "
                          style="cursor: pointer"
                          @click="open(file.url)"
                        >
                          {{ file.name }}
                        </div>
                      </v-hover>
                      <span
                        class="body-2 font-weight-regular onSurface_light--text"
                      >
                        {{
                          `X: ${file.dimensions?.x}mm, Y: ${file.dimensions?.y}mm, Z: ${file.dimensions?.z}mm`
                        }}
                      </span>
                    </div>
                    <div
                      class="d-flex flex-column"
                      v-for="(option, i) in file.option"
                      :key="i"
                    >
                      <div class="d-flex justify-space-between align-center">
                        <span class="onSurface_normal--text subtitle-1">
                          {{
                            `${option?.method} / ${option?.material} / ${option?.color} (${option?.layer} / ${option?.wall} / ${option?.infill})`
                          }}
                        </span>
                        <v-divider class="mx-2" />
                        <span class="onSurface_normal--text subtitle-1">
                          {{ `수량 ${option?.totalQty}개` }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import setMeta from "@/utils/setMeta";

export default {
  props: { printList: Array },
  data() {
    return {
      headers: [
        {
          text: "주문정보",
          value: "displayName",
        },
        {
          text: "작업장소",
          value: "workspace",
        },
        { text: "결제", value: "paymentMethod" },
        { text: "배송", value: "shippingMethod" },
        { text: "수량", value: "finishQty" },
        { text: "저장", value: "save", align: "center" },
      ],
    };
  },
  beforeCreate() {
    setMeta({
      title: "일정 관리",
      description: "메이드올 관리자페이지입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
  methods: {
    open(url) {
      window.open(url);
    },
  },
};
</script>
